import React from 'react'
import '../scss/policy.scss'
import { Link, useNavigate } from 'react-router-dom'
import { CButton } from '@coreui/react'

import AppFooter from './AppFooter'

const PrivatePolicy = () => {
  const navigate = useNavigate()

  return (
    <div className="p-container">
      <div className="p-contents">
        <Link to="/">
          <img src="https://smartceleb.mycafe24.com/wp-content/uploads/2022/01/smart_logo.png"></img>
        </Link>
        <h1>개인정보처리방침</h1>
        <a href="https://www.law.go.kr/LSW/lsInfoP.do?lsId=011357&ancYnChk=0#0000">
          ㅡ 개인정보 보호법 바로가기
        </a>
        <p>제1장 총칙</p>
        <span>
          제1조(목적) 이 법은 개인정보의 처리 및 보호에 관한 사항을 정함으로써 개인의 자유와 권리를
          보호하고, 나아가 개인의 존엄과 가치를 구현함을 목적으로 한다.
        </span>

        <p> 제2조(정의)</p>
        <span>
          이 법에서 사용하는 용어의 뜻은 다음과 같다. / 개정 2014. 3. 24., 2020. 2. 4. 1.
          “개인정보”란 살아 있는 개인에 관한 정보로서 다음 각 목의 어느 하나에 해당하는 정보를
          말한다. 가. 성명, 주민등록번호 및 영상 등을 통하여 개인을 알아볼 수 있는 정보 나. 해당
          정보만으로는 특정 개인을 알아볼 수 없더라도 다른 정보와 쉽게 결합하여 알아볼 수 있는 정보.
          이 경우 쉽게 결합할 수 있는지 여부는 다른 정보의 입수 가능성 등 개인을 알아보는 데
          소요되는 시간, 비용, 기술 등을 합리적으로 고려하여야 한다. 다. 가목 또는 나목을 제1호의2에
          따라 가명처리함으로써 원래의 상태로 복원하기 위한 추가 정보의 사용ㆍ결합 없이는 특정
          개인을 알아볼 수 없는 정보(이하 “가명정보”라 한다) 1의2. “가명처리”란 개인정보의 일부를
          삭제하거나 일부 또는 전부를 대체하는 등의 방법으로 추가 정보가 없이는 특정 개인을 알아볼
          수 없도록 처리하는 것을 말한다. 2. “처리”란 개인정보의 수집, 생성, 연계, 연동, 기록, 저장,
          보유, 가공, 편집, 검색, 출력, 정정(訂正), 복구, 이용, 제공, 공개, 파기(破棄), 그 밖에 이와
          유사한 행위를 말한다. 3. “정보주체”란 처리되는 정보에 의하여 알아볼 수 있는 사람으로서 그
          정보의 주체가 되는 사람을 말한다. 4. “개인정보파일”이란 개인정보를 쉽게 검색할 수 있도록
          일정한 규칙에 따라 체계적으로 배열하거나 구성한 개인정보의 집합물(集合物)을 말한다. 5.
          “개인정보처리자”란 업무를 목적으로 개인정보파일을 운용하기 위하여 스스로 또는 다른 사람을
          통하여 개인정보를 처리하는 공공기관, 법인, 단체 및 개인 등을 말한다. 6. “공공기관”이란
          다음 각 목의 기관을 말한다. 가. 국회, 법원, 헌법재판소, 중앙선거관리위원회의 행정사무를
          처리하는 기관, 중앙행정기관(대통령 소속 기관과 국무총리 소속 기관을 포함한다) 및 그 소속
          기관, 지방자치단체 나. 그 밖의 국가기관 및 공공단체 중 대통령령으로 정하는 기관 7.
          “영상정보처리기기”란 일정한 공간에 지속적으로 설치되어 사람 또는 사물의 영상 등을
          촬영하거나 이를 유ㆍ무선망을 통하여 전송하는 장치로서 대통령령으로 정하는 장치를 말한다.
          8. “과학적 연구”란 기술의 개발과 실증, 기초연구, 응용연구 및 민간 투자 연구 등 과학적
          방법을 적용하는 연구를 말한다.
        </span>
        <p>제1장 총칙</p>
        <span>
          제1조(목적) 이 법은 개인정보의 처리 및 보호에 관한 사항을 정함으로써 개인의 자유와 권리를
          보호하고, 나아가 개인의 존엄과 가치를 구현함을 목적으로 한다.
        </span>

        <p> 제2조(정의)</p>
        <span>
          이 법에서 사용하는 용어의 뜻은 다음과 같다. / 개정 2014. 3. 24., 2020. 2. 4. 1.
          “개인정보”란 살아 있는 개인에 관한 정보로서 다음 각 목의 어느 하나에 해당하는 정보를
          말한다. 가. 성명, 주민등록번호 및 영상 등을 통하여 개인을 알아볼 수 있는 정보 나. 해당
          정보만으로는 특정 개인을 알아볼 수 없더라도 다른 정보와 쉽게 결합하여 알아볼 수 있는 정보.
          이 경우 쉽게 결합할 수 있는지 여부는 다른 정보의 입수 가능성 등 개인을 알아보는 데
          소요되는 시간, 비용, 기술 등을 합리적으로 고려하여야 한다. 다. 가목 또는 나목을 제1호의2에
          따라 가명처리함으로써 원래의 상태로 복원하기 위한 추가 정보의 사용ㆍ결합 없이는 특정
          개인을 알아볼 수 없는 정보(이하 “가명정보”라 한다) 1의2. “가명처리”란 개인정보의 일부를
          삭제하거나 일부 또는 전부를 대체하는 등의 방법으로 추가 정보가 없이는 특정 개인을 알아볼
          수 없도록 처리하는 것을 말한다. 2. “처리”란 개인정보의 수집, 생성, 연계, 연동, 기록, 저장,
          보유, 가공, 편집, 검색, 출력, 정정(訂正), 복구, 이용, 제공, 공개, 파기(破棄), 그 밖에 이와
          유사한 행위를 말한다. 3. “정보주체”란 처리되는 정보에 의하여 알아볼 수 있는 사람으로서 그
          정보의 주체가 되는 사람을 말한다. 4. “개인정보파일”이란 개인정보를 쉽게 검색할 수 있도록
          일정한 규칙에 따라 체계적으로 배열하거나 구성한 개인정보의 집합물(集合物)을 말한다. 5.
          “개인정보처리자”란 업무를 목적으로 개인정보파일을 운용하기 위하여 스스로 또는 다른 사람을
          통하여 개인정보를 처리하는 공공기관, 법인, 단체 및 개인 등을 말한다. 6. “공공기관”이란
          다음 각 목의 기관을 말한다. 가. 국회, 법원, 헌법재판소, 중앙선거관리위원회의 행정사무를
          처리하는 기관, 중앙행정기관(대통령 소속 기관과 국무총리 소속 기관을 포함한다) 및 그 소속
          기관, 지방자치단체 나. 그 밖의 국가기관 및 공공단체 중 대통령령으로 정하는 기관 7.
          “영상정보처리기기”란 일정한 공간에 지속적으로 설치되어 사람 또는 사물의 영상 등을
          촬영하거나 이를 유ㆍ무선망을 통하여 전송하는 장치로서 대통령령으로 정하는 장치를 말한다.
          8. “과학적 연구”란 기술의 개발과 실증, 기초연구, 응용연구 및 민간 투자 연구 등 과학적
          방법을 적용하는 연구를 말한다.
        </span>
        <p>제1장 총칙</p>
        <span>
          제1조(목적) 이 법은 개인정보의 처리 및 보호에 관한 사항을 정함으로써 개인의 자유와 권리를
          보호하고, 나아가 개인의 존엄과 가치를 구현함을 목적으로 한다.
        </span>

        <p> 제2조(정의)</p>
        <span>
          이 법에서 사용하는 용어의 뜻은 다음과 같다. / 개정 2014. 3. 24., 2020. 2. 4. 1.
          “개인정보”란 살아 있는 개인에 관한 정보로서 다음 각 목의 어느 하나에 해당하는 정보를
          말한다. 가. 성명, 주민등록번호 및 영상 등을 통하여 개인을 알아볼 수 있는 정보 나. 해당
          정보만으로는 특정 개인을 알아볼 수 없더라도 다른 정보와 쉽게 결합하여 알아볼 수 있는 정보.
          이 경우 쉽게 결합할 수 있는지 여부는 다른 정보의 입수 가능성 등 개인을 알아보는 데
          소요되는 시간, 비용, 기술 등을 합리적으로 고려하여야 한다. 다. 가목 또는 나목을 제1호의2에
          따라 가명처리함으로써 원래의 상태로 복원하기 위한 추가 정보의 사용ㆍ결합 없이는 특정
          개인을 알아볼 수 없는 정보(이하 “가명정보”라 한다) 1의2. “가명처리”란 개인정보의 일부를
          삭제하거나 일부 또는 전부를 대체하는 등의 방법으로 추가 정보가 없이는 특정 개인을 알아볼
          수 없도록 처리하는 것을 말한다. 2. “처리”란 개인정보의 수집, 생성, 연계, 연동, 기록, 저장,
          보유, 가공, 편집, 검색, 출력, 정정(訂正), 복구, 이용, 제공, 공개, 파기(破棄), 그 밖에 이와
          유사한 행위를 말한다. 3. “정보주체”란 처리되는 정보에 의하여 알아볼 수 있는 사람으로서 그
          정보의 주체가 되는 사람을 말한다. 4. “개인정보파일”이란 개인정보를 쉽게 검색할 수 있도록
          일정한 규칙에 따라 체계적으로 배열하거나 구성한 개인정보의 집합물(集合物)을 말한다. 5.
          “개인정보처리자”란 업무를 목적으로 개인정보파일을 운용하기 위하여 스스로 또는 다른 사람을
          통하여 개인정보를 처리하는 공공기관, 법인, 단체 및 개인 등을 말한다. 6. “공공기관”이란
          다음 각 목의 기관을 말한다. 가. 국회, 법원, 헌법재판소, 중앙선거관리위원회의 행정사무를
          처리하는 기관, 중앙행정기관(대통령 소속 기관과 국무총리 소속 기관을 포함한다) 및 그 소속
          기관, 지방자치단체 나. 그 밖의 국가기관 및 공공단체 중 대통령령으로 정하는 기관 7.
          “영상정보처리기기”란 일정한 공간에 지속적으로 설치되어 사람 또는 사물의 영상 등을
          촬영하거나 이를 유ㆍ무선망을 통하여 전송하는 장치로서 대통령령으로 정하는 장치를 말한다.
          8. “과학적 연구”란 기술의 개발과 실증, 기초연구, 응용연구 및 민간 투자 연구 등 과학적
          방법을 적용하는 연구를 말한다.
        </span>
        <div className="btn-container">
          {localStorage.mem_name == undefined ? (
            <CButton
              color="success"
              type="button"
              onClick={() => {
                window.close()
              }}
            >
              Close
            </CButton>
          ) : (
            <CButton
              color="success"
              type="button"
              onClick={() => {
                navigate('/base/base')
              }}
            >
              메인 창으로 이동
            </CButton>
          )}
        </div>
      </div>
      <div className="p-footer">
        <AppFooter></AppFooter>
      </div>
    </div>
  )
}

export default React.memo(PrivatePolicy)
